import React from "react";

export default function Loading(){
  return(
    <div id="ldng">
      <div id="ldng_cntr">
        <figure>
          <img src="./loading.gif" alt="Loading"></img>
        </figure>
        <p>BUSCANDO</p>
      </div>
    </div>
  );
}