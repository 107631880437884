export const varConf = (type) => {
  let placeholder; 
  let image; 
  let dpd_id; 
  let dpd_input_id; 

  switch(type){
    case "year":
      placeholder = "AÑO"; 
      image = "./dropdown-year.svg";
      dpd_id = "dpd_year"; 
      dpd_input_id = "dpd_year_input"
      break; 
    case "builder":
      placeholder = "ARMADORA"; 
      image = "./dropdown-builder.svg";
      dpd_id = "dpd_builder";
      dpd_input_id = "dpd_builder_input";
      break; 
    case "model":
      placeholder = "MODELO"; 
      image = "./dropdown-model.svg";
      dpd_id = "dpd_model";
      dpd_input_id = "dpd_model_input";
      break; 
    case "engine":
      placeholder = "MOTOR"; 
      image = "./dropdown-engine.svg";
      dpd_id = "dpd_engine";
      dpd_input_id = "dpd_engine_input";
      break; 
    case "part":
      placeholder = "AUTOPARTE"; 
      image = "./dropdown-part.svg";
      dpd_id = "dpd_part";
      dpd_input_id = "dpd_part_input";
      break; 
    case "brand":
      placeholder = "MARCA"; 
      image = "./dropdown-part.svg";
      dpd_id = "dpd_brand";
      dpd_input_id = "dpd_brand_input";
      break; 
    default:
      break; 
    }
  
  return { placeholder, image, dpd_id, dpd_input_id }
};
