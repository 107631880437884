import React, { useState } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function AutopartRow(props) {
  const [clickToCopy, setClickToCopyState] = useState(false);

  return (
    <div key={props.i} className="rslt_rw" onClick={() => {
      props.setSelectedPartState(props.parts[props.i])
      props.setDetailsState(true);
    }}>
      <div className="col_1">
        <figure>
          <img src={`/brands/${props.parts[props.i].Marca}.png`} alt="Brand"></img>
        </figure>
        <p>{props.parts[props.i].Marca}</p>
      </div>
      <div className="col_2"
        onClick={(event) => event.stopPropagation()}
        onMouseEnter={() => setClickToCopyState(true)}
        onMouseLeave={() => setClickToCopyState(false)}
        >
          <p>{props.parts[props.i].Part}</p>
          {clickToCopy && <CopyToClipboard text={props.parts[props.i].Part}><figure id="ctc" onClick={(event) => {
            event.stopPropagation();
            setClickToCopyState(false);
          }}><img src="/ctc.svg" alt="Click to copy"></img></figure></CopyToClipboard>}
        </div>
      <p className="col_3">{props.parts[props.i].PartType} {props.parts[props.i].MfrLabel}</p>
      <p className="col_4">{props.parts[props.i].Position ? props.parts[props.i].Position : '--'}</p>
    </div>
  );
}