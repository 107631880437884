import React from "react";

export default function NotFound(){
  return(
    <div id="ntf">
      <figure>
        <img src="./not-found.svg" alt="Not Found"></img>
      </figure>
      <p>Lo sentimos, no disponemos de la autoparte buscada.</p>
    </div>
  );
}
