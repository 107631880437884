import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function CarouselComponent(){
  return(
    <Carousel showThumbs={false} showStatus={false} swipeable={true} useKeyboardArrows={true} infiniteLoop={true} autoPlay={true} emulateTouch={true}>
      <div>
        <img src="/carrusel/1.png" alt="Publicidad"/>
      </div>
      <div>
        <img src="/carrusel/2.png" alt="Publicidad"/>
      </div>
      <div>
        <img src="/carrusel/3.png" alt="Publicidad"/>
      </div>
  </Carousel>
  );
}