import React, { useState, useEffect } from "react";
import Details from './Details';
import CustomDropdown from './CustomDropdown/CustomDropdown';
import AutopartRow from "./AutopartRow";

export default function Autoparts(props){
  const [detailsActivated, setDetailsState] = useState(false);
  const [selectedPart, setSelectedPartState] = useState(false);
  const [selectedBrand, setSelectedBrandState] = useState(false);
  const [brandSelected, setBrandSelected] = useState(false);
  const [results, setResultsState] = useState([]);
  const brands = props.brands || [];

  const addRow = (parts, resultsCopy, i) => {
    resultsCopy.push(<AutopartRow parts={parts} resultsCopy={resultsCopy} i={i} setSelectedPartState={setSelectedPartState} setDetailsState={setDetailsState}></AutopartRow>)
  }
  
  useEffect(() => {
    const setResults = () => {
      const parts = props.results; 
      const resultsCopy = []; 
      for(let i = 0; i < parts.length ; i++){
        addRow(parts, resultsCopy, i);
      }
      setResultsState(resultsCopy);
    }
    
    const parts = props.results; 
    const resultsCopy = []; 
    if(brandSelected){
      if(selectedBrand === 'Todas'){
        setResults();
      }else{
        for(let i = 0; i < parts.length ; i++){
          if(parts[i].Marca === selectedBrand){
            addRow(parts, resultsCopy, i);
          }
        }
        setResultsState(resultsCopy);
      }
    }else{
      setResults(); 
    }
  }, [brandSelected, props.results, selectedBrand]);
  
  return(
    <div id="rslt">
      {detailsActivated && <Details setDetailsState={setDetailsState} details={selectedPart}></Details>}
      <div id="rslt_fltr">
        <CustomDropdown type="brand" disabled={false} dropdownValues={['Todas', ...brands]} completed={(value) => {setSelectedBrandState(value); setBrandSelected(true)}}></CustomDropdown>
      </div>
      <p id="rslt_amnt">{results.length} Resultados de búsqueda</p>
      <div id="rslt_hdr">
        <p className="col_1">Marca</p>
        <p className="col_2">Número de parte</p>
        <p className="col_3">Tipo de parte</p>
        <p className="col_4">Posición</p>
      </div>
      <div id="rslt_rws">
        {results}
      </div>
    </div>
  )
}