import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Details(props) {
  const [applications, setApplicationsState] = useState(false);
  const [executeOnce, setExecutionState] = useState(true);
  let applicationsOperation = [];

  useEffect(() => {
    if (executeOnce) {
      setExecutionState(false);
      try {
        axios
          .get(
            `${process.env.REACT_APP_GET_COMPATIBLE_CAR}?part=${props.details.Part}`
          )
          .then((result) => {
            const data = JSON.parse(result.data.body).applications;
            for (let i = 0; i < data.length; i++) {
              applicationsOperation.push(
                <div key={i} className="dtls_rw">
                  <p className="dtls_col_1">{data[i].Year}</p>
                  <p className="dtls_col_2">{data[i].Make}</p>
                  <p className="dtls_col_3">{data[i].Model}</p>
                  <p className="dtls_col_4">{`${
                    data[i].Cylinders ? data[i].Cylinders : "--"
                  } Cil en ${
                    data[i].EngineBlockType ? data[i].EngineBlockType : "--"
                  } de ${data[i].Liter ? data[i].Liter : "--"} Lts`}</p>
                </div>
              );
            }
            setApplicationsState(applicationsOperation);
          });
      } catch (err) {
        console.log(err);
      }
    }
  }, [applications, applicationsOperation, executeOnce, props.details.Part]);

  return (
    <div
      id="dtls"
      onClick={(event) => {
        if (event.target.id === "dtls") {
          props.setDetailsState(false);
        }
      }}
    >
      <div id="dtls_cntr">
        <p className="dtls_ttle">Atributos</p>
        <div id="dtls_attr">
          <figure>
            <img src={`/brands/${props.details.Marca}.png`} alt="Brand"></img>
          </figure>
          <div>
            <div>
              <div>
                <p className="dtls_attr_ttle">Descripción</p>
                <p>{props.details.Note ? props.details.Note : "---"}</p>
              </div>
              <div>
                <p className="dtls_attr_ttle">Tracción</p>
                <p>
                  {props.details.DriveType ? props.details.DriveType : "---"}
                </p>
              </div>
            </div>
            <div>
              <div>
                <p className="dtls_attr_ttle">Transmisión</p>
                <p>
                  {props.details.TransmissionControlType
                    ? props.details.TransmissionControlType
                    : "---"}
                </p>
              </div>
              <div>
                <p className="dtls_attr_ttle">Particularidad</p>
                <p>
                  {props.details.CylinderHeadType
                    ? props.details.CylinderHeadType
                    : "---"}
                </p>
              </div>
            </div>
            <div>
              <div>
                <p className="dtls_attr_ttle">Notas</p>
                <p>---</p>
              </div>
            </div>
          </div>
        </div>
        <p className="dtls_ttle">Aplicaciones</p>
        <div id="dtls_hdr">
          <p className="dtls_col_1">Año</p>
          <p className="dtls_col_2">Armadora</p>
          <p className="dtls_col_3">Modelo</p>
          <p className="dtls_col_4">Motor</p>
        </div>
        <div id="dtls_rws">
          {applications || (
            <figure id="dtls_ldng">
              <img src="./loading.gif" alt="Loading"></img>
            </figure>
          )}
        </div>
      </div>
    </div>
  );
}
