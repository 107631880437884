import React, { useState } from "react";
import NotFound from './NotFound';
import Autoparts from './Autoparts';

export default function SearchResults(props){
  const [notFoundActivated, setNotFoundState] = useState(false);
  const [resultsActivated, setResultsState] = useState(true);

  return(
    <div id="main">
      {resultsActivated && <Autoparts results={props.results} brands={props.brands}></Autoparts>}
      {notFoundActivated && <NotFound></NotFound>}
    </div>
  );
}
