import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import CustomDropdown from './CustomDropdown/CustomDropdown';
import Carousel from './Carousel';
import Loading from './Loading'; 
import SearchResults from './SearchResults';
import { getYear, getBuilder, getModel, getEngine, getPart, getAutopart } from './httpRequests';

export default function Main() {
  const [searchtoolStep, setSearchtoolStepState] = useState(0);
  const [websiteFirstLoad, setWebsiteFirstLoadState] = useState(true);
  const [isWebsiteFirstSearch, setWebsiteFirstSearchState] = useState(true);
  const [loadingActivated, setLoadingState] = useState(false);
  const [searchResults, setSearchResults] = useState(false);
  const [brands, setBrandsState] = useState(null);

  // Dropdown data and dropdown item selected
  const [years, setYearsState] = useState(null);
  const [yearSelected, setYearSelected] = useState(null);
  const [builders, setBuildersState] = useState(null);
  const [builderSelected, setBuilderSelected] = useState(null);
  const [models, setModelsState] = useState(null);
  const [modelSelected, setModelSelected] = useState(null);
  const [engines, setEnginesState] = useState([]);
  const [engineSelected, setEngineSelected] = useState(null);
  const [parts, setPartsState] = useState(null);
  const [partSelected, setPartSelected] = useState(null);
  
  useEffect(() => {
    switch(searchtoolStep){
      case 0: 
        if(websiteFirstLoad){
          getYear({setYearsState}); 
          setWebsiteFirstLoadState(false); 
        }

        // Clean dropdown data
        setYearSelected(null); 
        setBuildersState(null);
        setBuilderSelected(null);
        setModelsState(null);
        setModelSelected(null);
        setEnginesState([]);
        setEngineSelected(null);
        setPartsState(null);
        setPartSelected(null);

        // Clean input value if it was fulfilled
        document.getElementById('dpd_year_input').value = '';
        document.getElementById('dpd_year_input').focus();

        break; 
      case 1: 
        // Clean dropdown data
        setBuildersState(null);
        setBuilderSelected(null);
        setModelsState(null);
        setModelSelected(null);
        setEnginesState([]);
        setEngineSelected(null);
        setPartsState(null);
        setPartSelected(null);

        // Clean input value if it was fulfilled
        document.getElementById('dpd_builder_input').value = '';
        document.getElementById('dpd_builder_input').focus();

        getBuilder({yearSelected, setBuildersState});
        break; 
      case 2: 
        // Clean dropdown data
        setModelsState(null);
        setModelSelected(null);
        setEnginesState([]);
        setEngineSelected(null);
        setPartsState(null);
        setPartSelected(null);

        // Clean input value if it was fulfilled
        document.getElementById('dpd_model_input').value = '';
        document.getElementById('dpd_model_input').focus();

        getModel({yearSelected, builderSelected, setModelsState});
        break; 
      case 3: 
        // Clean dropdown data
        setEnginesState(null);
        setEnginesState([]);
        setPartsState(null);
        setPartSelected(null);

        // Clean input value if it was fulfilled
        document.getElementById('dpd_engine_input').value = '';
        document.getElementById('dpd_engine_input').focus();

        getEngine({yearSelected, builderSelected, modelSelected, setEnginesState});
        break; 
      case 4: 
        // Clean dropdown data
        setPartsState(null);
        setPartSelected(null);

        // Clean input value if it was fulfilled
        document.getElementById('dpd_part_input').value = '';
        document.getElementById('dpd_part_input').focus();

        getPart({yearSelected, builderSelected, modelSelected, engineSelected, setPartsState});
        break; 
      case 5: 
        document.getElementById('srch_btn').focus();
        break; 
      default: 
        break;   
    }
  }, [builderSelected, engineSelected, modelSelected, searchtoolStep, websiteFirstLoad, yearSelected, years]); 

  function SearchButton(props){
    if(props.disabled){
      return(
        <button id="srch_btn" className="dpd srch_btn_disabled">BUSCAR</button>
      );
    }else{
      return(
        <button id="srch_btn" className="dpd" 
          onClick={() => {
            props.setLoadingState(!props.loadingActivated); 
            getAutopart({yearSelected, builderSelected, modelSelected, engineSelected, partSelected, setSearchResults, setBrandsState, setWebsiteFirstSearchState, setLoadingState});
          }}
        >BUSCAR</button>
      );
    }
  }

  function CleanFilters(props){
    return <button id="cln_flt" className="dpd" onClick={() => {
      setSearchtoolStepState(0);
      setWebsiteFirstSearchState(true);
    }}>NUEVA BÚSQUEDA</button>
  }

  return (
    <div id="app">
      {loadingActivated && <Loading></Loading>}
      <div id="hdr">
        <figure id="lgo">
          <img src="./logo.svg" alt="Logo"></img>
        </figure>
        <div id="srch_and_btns">
          <div id="srch">
            <CustomDropdown type="year" valueSelected={yearSelected} disabled={!(searchtoolStep >= 0)} dropdownValues={years} completed={(value) => {setYearSelected(value); setSearchtoolStepState(1)}}></CustomDropdown>
            <CustomDropdown type="builder" valueSelected={builderSelected} disabled={!(searchtoolStep >= 1)} dropdownValues={builders} completed={(value) => {setBuilderSelected(value); setSearchtoolStepState(2)}}></CustomDropdown>
            <CustomDropdown type="model" valueSelected={modelSelected} disabled={!(searchtoolStep >= 2)} dropdownValues={models} completed={(value) => {setModelSelected(value); setSearchtoolStepState(3)}} ></CustomDropdown>
            <CustomDropdown type="engine" valueSelected={engineSelected} disabled={!(searchtoolStep >= 3)} dropdownValues={['Todos', ...engines]} completed={(value) => {setEngineSelected(value); setSearchtoolStepState(4)}}></CustomDropdown>
            <CustomDropdown type="part" valueSelected={partSelected} disabled={!(searchtoolStep >= 4)} dropdownValues={parts} completed={(value) => {setPartSelected(value); setSearchtoolStepState(5)}} emptyValue={() => setPartSelected(null)}></CustomDropdown>
            <div id="srch_btns">
              <SearchButton setLoadingState={setLoadingState} loadingActivated={loadingActivated} disabled={!engineSelected}></SearchButton>
              <CleanFilters></CleanFilters>
            </div>
          </div>
          <div id="srch_btns_big">
            <SearchButton setLoadingState={setLoadingState} loadingActivated={loadingActivated} disabled={!engineSelected}></SearchButton>
            <CleanFilters></CleanFilters>
          </div>
        </div>
      </div>
      <Router>  
        <Switch>
          <Route path="/">
            {isWebsiteFirstSearch ? <Carousel></Carousel> : <SearchResults results={searchResults} brands={brands}/>}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}