import axios from 'axios';

export const getYear =  async (params) => {
  try{
    const result = await axios.get(process.env.REACT_APP_GET_YEARS);
    const data = JSON.parse(result.data.body).year; 
    params.setYearsState(data); 
  }catch(err){
    console.log(err);
  }
}

export const getBuilder = async (params) => {
  try{
    const result = await axios.get(`${process.env.REACT_APP_GET_BUILDER}?year=${params.yearSelected}`);
    const data = JSON.parse(result.data.body).builder; 
    params.setBuildersState(data); 
  }catch(err){
    console.log(err);
  }
}

export const getModel = async (params) => {
  try{
    const result = await axios.get(`${process.env.REACT_APP_GET_MODEL}?year=${params.yearSelected}&builder=${params.builderSelected}`);
    const data = JSON.parse(result.data.body).model; 
    params.setModelsState(data); 
  }catch(err){
    console.log(err);
  }
}

export const getEngine = async (params) => {
  try{
    const result = await axios.get(`${process.env.REACT_APP_GET_ENGINE}?year=${params.yearSelected}&builder=${params.builderSelected}&model=${params.modelSelected}`);
    const data = JSON.parse(result.data.body).engine;
     
    // REVERT IT IN "parseEngine" METHOD
    const stringifiedData = []; 
    data.forEach(element => {
      if(element.Cylinders && element.EngineBlockType && element.Liter){
        stringifiedData.push(`${element.Cylinders ? element.Cylinders: '--'} Cil en ${element.EngineBlockType ? element.EngineBlockType : '--'} de ${element.Liter ? element.Liter : '--'} Lts`)
      }
    });  
    params.setEnginesState(stringifiedData); 
  }catch(err){
    console.log(err);
  }
}

export const getPart = async (params) => {
  try{
    let result; 
    if(params.engineSelected === 'Todos'){
      result = await axios.get(`${process.env.REACT_APP_GET_PART}?year=${params.yearSelected}&builder=${params.builderSelected}&model=${params.modelSelected}&engine=all`);
    }else {
      let {cylinders, blocktype, liters} = parseEngine(params);
      result = await axios.get(`${process.env.REACT_APP_GET_PART}?year=${params.yearSelected}&builder=${params.builderSelected}&model=${params.modelSelected}&cylinders=${cylinders}&blocktype=${blocktype}&liters=${liters}`);
    }    

    const data = JSON.parse(result.data.body).part; 
    params.setPartsState(data); 
  }catch(err){
    console.log(err);
  }
}

export const getAutopart = async (params) => {
  let result; 
  if(params.engineSelected === 'Todos'){
    result = await axios.get(`${process.env.REACT_APP_GET_AUTOPART}?year=${params.yearSelected}&builder=${params.builderSelected}&model=${params.modelSelected}${params.partSelected ? `&partName=${params.partSelected}`: ''}&engine=all`);
  }else {
    let {cylinders, blocktype, liters} = parseEngine(params);
    result = await axios.get(`${process.env.REACT_APP_GET_AUTOPART}?year=${params.yearSelected}&builder=${params.builderSelected}&model=${params.modelSelected}&cylinders=${cylinders}&blocktype=${blocktype}&liters=${liters}${params.partSelected ? `&partName=${params.partSelected}`: ''}`);
  }

  const data = JSON.parse(result.data.body).part; 
  params.setSearchResults(data);
  const brands = []; 
  data.forEach(part => {
    if(brands.indexOf(part.Marca) === -1) {
      brands.push(part.Marca);
    }
  });   
  params.setBrandsState(brands);
  params.setLoadingState(false);
  params.setWebsiteFirstSearchState(false);
}

function parseEngine(params){
  let cylinders = params.engineSelected.match(/.* Cil/g); 
  cylinders = cylinders[0].replace(' Cil', ''); 
  cylinders = cylinders === '--' ? 'null': cylinders; 

  let blocktype = params.engineSelected.match(/en.*de/g); 
  blocktype = blocktype[0].replace('en ', '');
  blocktype = blocktype.replace(' de', ''); 
  blocktype = blocktype === '--' ? 'null': blocktype; 

  let liters = params.engineSelected.match(/de.*/g); 
  liters = liters[0].replace('de ', '');
  liters = liters.replace(' Lts', ''); 
  liters = liters === '--' ? 'null': liters; 
  return {cylinders, blocktype, liters}
}
